import React, { useState, useRef } from "react";
import { View, Modal, TouchableOpacity, StyleSheet } from "react-native";
import axios from "axios";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome5,
} from "@expo/vector-icons";

export default function GSF() {
  const onClick = () => {
    axios({
      method: "get",
      // url:url,
      url: `https://api.zorabots.be/api/v1/webhooks/e9c6f7ef-c25d-4fe8-9e0f-876ce3bd8f6b/trigger`,
    }).then((response) => {
      console.log(response.data);
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.contai}>
        <TouchableOpacity onPress={onClick} style={styles.button}>
          YES
        </TouchableOpacity>
      </View>
      <img style={styles.img} src={require("/assets/wel2.jpg")} />
    </View>
  );
}

const styles = StyleSheet.create({
img:{
    width: "100%",
    maxHeight: "1700px"
},
  button: {
    width: "150px",
    backgroundColor: "#c03ef7",
    textAlign: "center",
    padding: "15px",
    fontSize: "2rem",
    borderRadius: "10px",
    fontFamily: "Helvetica",
    color: "white",
    fontWeight: "800",
  },
  
  contai: {
    position: "absolute",
    top: "600px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
