import React, { useState, useRef } from "react";
import { View, Modal, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import axios from "axios";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome5,
} from "@expo/vector-icons";

export default function Room104() {
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const imgRef = useRef(null);
  const [markedPoints, setMarkedPoints] = useState([
    {
      x: 580,
      y: 300,
      url: "ed51c46b-c376-48ff-b933-bc22d30f4ab4",
      icon: "person-outline",
    },
    {
      x: 948,
      y: 300,
      url: "917fc33b-d441-4984-a944-a66bce098571",
      icon: "person-outline",
    },
    {
      x: 1300,
      y: 300,
      url: "50a64d56-cca5-4fc6-9a9a-005afd2fbcb3",
      icon: "person-outline",
    },
    {
      x: 580,
      y: 723,
      url: "db39f9b7-9dcb-4ba5-b929-1c5f59a2eaf7",
      icon: "person-outline",
    },
    {
      x: 948,
      y: 723,
      url: "a0d83475-2d1e-4cb1-ba60-3446d3003d26",
      icon: "person-outline",
    },
    {
      x: 1300,
      y: 723,
      url: "5bba88c5-62fe-40eb-a50e-0a55dbd158fb",
      icon: "person-outline",
    },
    {
      x: 1100,
      y: 0,
      url: "5a88f6ac-7a46-4081-85ce-9996a412113f",
      icon: "fast-food-outline",
    },
    {
      x: 322,
      y: 510,
      url: "999b63af-3761-4fbc-97ee-0964863e797f",
      icon: "location-outline",
    },
    {
      x: 950,
      y: 100,
      url: "570bcb5a-de95-431f-b56b-1f2ebdf84aed",
      icon: "door-sliding",
    }, // entree 104
    {
      x: 950,
      y: 0,
      url: "6f739b9c-0b51-45bc-a010-5f7c7ebb3a8f",
      icon: "stairs",
    }, //escaliers

    // Add more points as needed
  ]);

  // Function to handle marking a point on the map
  const markPoint = (x, y) => {
    setMarkedPoints([...markedPoints, { x, y }]);
  };

  Dimensions.addEventListener('change', () => {
    setImageWidth(imgRef.current.offsetWidth);
    setImageHeight(imgRef.current.offsetHeight);
});

  const onMarkClick = (url) => {
    axios({
      method: "get",
      // url:url,
      url: `https://api.zorabots.be/api/v1/webhooks/${url}/trigger`,
    }).then((response) => {
      console.log(response.data);
    });
  };

  // Function to update image dimensions
  const updateImageDimensions = () => {
    if (imgRef.current) {
      setImageWidth(imgRef.current.offsetWidth);
      setImageHeight(imgRef.current.offsetHeight);
      console.log(imgRef.current.offsetWidth);
    }
  };

  return (
    <View style={styles.container}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <div
              style={{ justifyContent: "end", display: "flex", width: "100%" }}
            >
              <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
                <Ionicons name={"close"} size={"2vw"} color="black" />
              </TouchableOpacity>
            </div>
            <img
              src={require("/assets/toilets.jpg")}
              style={styles.mapImage2}
            />
          </View>
        </View>
      </Modal>

      <img
        src={require("/assets/map.jpg")}
        onLoad={updateImageDimensions}
        ref={imgRef}
        style={styles.mapImage}
      />

      <TouchableOpacity
        onPress={() => setModalVisible(!modalVisible)}
        style={[
          styles.markedPoint2,
          {
            left: `calc(${(800 * imageWidth) / 1920}px - 2vw)`,
            top: `calc(${0 / 1080}px - 2vw)`,
          },
        ]}
      >
        <FontAwesome5 name={"restroom"} size={"2vw"} color="white" />
      </TouchableOpacity>

      {markedPoints.map((point, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => onMarkClick(point.url)}
          style={[
            styles.markedPoint,
            {
              left: `calc(${(point.x * imageWidth) / 1920}px - 2vw)`,
              top: `calc(${(point.y * imageHeight) / 1080}px - 2vw)`,
            },
          ]}
        >
          {point.icon == "door-sliding" || point.icon == "stairs" ? (
            <MaterialCommunityIcons
              name={point.icon}
              size={"2vw"}
              color="white"
            />
          ) : (
            <Ionicons name={point.icon} size={"2vw"} color="white" />
          )}
        </TouchableOpacity>
      ))}

      {/* Add more buttons or interactions as needed */}
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    top: "10%",
    left: "10%",
  },
  mapImage: {
    height: "auto",
    maxWidth: "80vw",
    position: "absolute",
    top: 0,
    left: 0,
  },
  mapImage2: {
    width: "70%",
  },
  markedPoint: {
    position: "absolute",
    width: "3.5vw",
    height: "3.5vw",
    backgroundColor: "#b31fd3",
    borderRadius: "4vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markedPoint2: {
    position: "absolute",
    width: "3.5vw",
    height: "3.5vw",
    backgroundColor: "#6816b5",
    borderRadius: "4vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "blue",
    borderRadius: 5,
  },
});
