import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Room104 from "./Room104";
import Welcome from "./Welcome";
import { Linking } from "react-native";
import GSF from "./GSF";
import BC from "./BC";
import Showroom from "./Showroom";


const Stack = createStackNavigator();
// Linking.addEventListener('url', handleOpenURL);

// function handleOpenURL(event) {
//   console.log("screenName")
//   const route = event.url.replace(/.*?:\/\//g, '');
//   const params = new URLSearchParams(route);

//   const screenName = params.get('screen');
//   console.log(screenName)
//   if (screenName) {
//     navigation.navigate(screenName);
//   }
// }

export default function App() {
  const linking = {
    config: {
      initialRouteName: "Home",
      screens: {
        Home: {
          path: "home",
        },
        Welcome: {
          path: "welcome",
        },
        GSF: {
          path: "gsf",
        },
        BC: {
          path: "bc",
        },
        Showroom: {
          path: "showroom",
        },
      },
    },
  };

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="Home" component={Room104} />
        <Stack.Screen name="Welcome" component={Welcome} />
        <Stack.Screen name="GSF" component={GSF} />
        <Stack.Screen name="BC" component={BC} />
        <Stack.Screen name="Showroom" component={Showroom} />
        {/* Add more screens as needed */}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
