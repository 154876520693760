import React, { useState, useRef } from "react";
import { View, Modal, TouchableOpacity, StyleSheet, Dimensions } from "react-native";
import axios from "axios";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome5,
} from "@expo/vector-icons";

export default function BC() {
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const imgRef = useRef(null);
  const [markedPoints, setMarkedPoints] = useState([
    {
      x: 670,
      y: 235,
      url: "https://api.zorabots.be/api/v1/webhooks/68caa50f-adfd-4169-8064-7ab11e64537c/trigger",
      icon: "door-open", // entrance
    },
    {
        x: 519,
        y: 578,
        url: "https://api.zorabots.be/api/v1/webhooks/7d095ba6-4ab3-4d83-abde-04a053ce9a09/trigger",
        icon: "suitcase", // bagage
      },
      {
        x: 580,
        y: 822,
        url: "https://api.zorabots.be/api/v1/webhooks/9c615ff5-3b90-41e8-8784-6065a95b6087/trigger",
        icon: "restroom", // toilettes
      },
      {
        x: 820,
        y: 629,
        url: "https://api.zorabots.be/api/v1/webhooks/776bc318-ecdc-469b-9d31-d2541783c308/trigger",
        icon: "vest", // bc entrance
      },
      {
        x: 924,
        y: 629,
        url: "https://api.zorabots.be/api/v1/webhooks/d8be341d-9a29-4488-8a4c-f568d26cd091/trigger",
        icon: "robot", // bc entrance
      },
      {
        x: 1168,
        y: 728,
        url: "https://api.zorabots.be/api/v1/webhooks/cdb31826-eb83-4478-9d11-63a8d33f038f/trigger",
        icon: "box", // load food
      },
      {
        x: 1298,
        y: 580,
        url: "https://api.zorabots.be/api/v1/webhooks/87e34197-5599-4b53-bea4-90cd92c0cf4f/trigger",
        icon: "utensils", //table
      },
      {
        x: 1367,
        y: 637,
        url: "https://api.zorabots.be/api/v1/webhooks/4ff9f2b0-2022-44c1-a47e-80e90f23bb2c/trigger",
        icon: "user", //guest 5
      },

      {
        x: 1287,
        y: 700,
        url: "https://api.zorabots.be/api/v1/webhooks/68689f18-e291-4e19-858f-0ae23aa1a0ec/trigger",
        icon: "user", //guest 4
      },
  
      {
        x: 1277,
        y: 770,
        url: "https://api.zorabots.be/api/v1/webhooks/77e5af8f-605e-45a2-b1ff-da752e11234b/trigger",
        icon: "user", //guest 3
      },
      {
        x: 1267,
        y: 840,
        url: "https://api.zorabots.be/api/v1/webhooks/3dd5cf91-5d57-435b-b507-29fbc68c514c/trigger",
        icon: "user", //guest 2
      },
      {
        x: 1257,
        y: 910,
        url: "https://api.zorabots.be/api/v1/webhooks/3adbe27d-f9f8-407e-bd06-f2fd027ebe1c/trigger",
        icon: "user", //guest 1
      },
      {
        x: 1447,
        y: 700,
        url: "https://api.zorabots.be/api/v1/webhooks/d509b8cf-8db0-4dff-8209-325acef55b05/trigger",
        icon: "user", //guest 6
      },
  
      {
        x: 1457,
        y: 770,
        url: "https://api.zorabots.be/api/v1/webhooks/f1e8a88b-6c12-443b-9473-660c428cbde8/trigger",
        icon: "user", //guest 7
      },
      {
        x: 1467,
        y: 840,
        url: "https://api.zorabots.be/api/v1/webhooks/113c15c1-f714-4a46-b945-45bc240aa651/trigger",
        icon: "user", //guest 8
      },
      {
        x: 1477,
        y: 910,
        url: "https://api.zorabots.be/api/v1/webhooks/219426b1-2daa-4dd3-adf1-1a857063c47e/trigger",
        icon: "user", //guest 9
      },
  ]);

  // Function to handle marking a point on the map
  const markPoint = (x, y) => {
    setMarkedPoints([...markedPoints, { x, y }]);
  };

  Dimensions.addEventListener('change', () => {
    setImageWidth(imgRef.current.offsetWidth);
    setImageHeight(imgRef.current.offsetHeight);
});

  const onMarkClick = (url) => {
    axios({
      method: "get",
      // url:url,
      url: url,
    }).then((response) => {
      console.log(response.data);
    });
  };

  // Function to update image dimensions
  const updateImageDimensions = () => {
    if (imgRef.current) {
      setImageWidth(imgRef.current.offsetWidth);
      setImageHeight(imgRef.current.offsetHeight);
      console.log(imgRef.current.offsetWidth);
    }
  };

  return (
    <View style={styles.container}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <div
              style={{ justifyContent: "end", display: "flex", width: "100%" }}
            >
              <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
                <Ionicons name={"close"} size={"2vw"} color="black" />
              </TouchableOpacity>
            </div>
            <img
              src={require("/assets/toilets.jpg")}
              style={styles.mapImage2}
            />
          </View>
        </View>
      </Modal>

      <img
        src={require("/assets/map_ent.png")}
        onLoad={updateImageDimensions}
        ref={imgRef}
        style={styles.mapImage}
      />


      {markedPoints.map((point, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => onMarkClick(point.url)}
          style={[
            styles.markedPoint,
            {
              left: `calc(${(point.x * imageWidth) / 1920}px - 1.5vw)`,
              top: `calc(${(point.y * imageHeight) / 1080}px - 1.5vw)`,
            },
          ]}
        >

            <FontAwesome5
              name={point.icon}
              size={"1.2vw"}
              color="white"
            />
          
        </TouchableOpacity>
      ))}

      {/* Add more buttons or interactions as needed */}
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    top: "10%",
    left: "10%",
  },
  mapImage: {
    height: "auto",
    maxWidth: "80vw",
    position: "absolute",
    top: 0,
    left: 0,
  },
  mapImage2: {
    width: "70%",
  },
  markedPoint: {
    position: "absolute",
    width: "2.8vw",
    height: "2.8vw",
    backgroundColor: "#b31fd3",
    borderRadius: "4vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markedPoint2: {
    position: "absolute",
    width: "3.5vw",
    height: "3.5vw",
    backgroundColor: "#6816b5",
    borderRadius: "4vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  markButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "blue",
    borderRadius: 5,
  },
});
